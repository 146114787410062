import { ManufacturerOrderStatusBadge } from 'features/purchases/components/ManufacturerOrderStatusBadge/ManufacturerOrderStatusBadge';
import PurchaseOrderStatusBadge from 'features/purchases/components/PurchaseOrderStatusBadge/PurchaseOrderStatusBadge';
import ManufacturerOrderCoreStatus, {
    ManufacturerOrderCoreStatusDisplay,
} from 'features/purchases/enums/ManufacturerOrderCoreStatus';
import PurchaseOrderStatus from 'features/purchases/enums/PurchaseOrderStatus';
import purchasesApi from 'features/purchases/purchases.api';
import React, { useMemo } from 'react';
import CardWidget from '../CardWidget/CardWidget';
import WidgetDisplay from '../WidgetDisplay/WidgetDisplay';

type DashboardOrdersData = {
    purchaseOrderStatus: Partial<Record<PurchaseOrderStatus, { count: number; value: number }>>;
    manufacturerOrderCoreStatus: Partial<
        Record<ManufacturerOrderCoreStatus, { count: number; value: number }>
    >;
};

export default function DashboardOrdersWidgets() {
    const query = purchasesApi.usePurchaseOrderListQuery({
        criteria: {
            status: '',
            archived: 'false',
            date: '-30',
        },
        paging: {
            limit: 10000,
            skip: 0,
        },
    });

    const data = useMemo((): DashboardOrdersData => {
        // init new model
        const result: DashboardOrdersData = {
            purchaseOrderStatus: {},
            manufacturerOrderCoreStatus: {},
        };

        // loop through all records and populate the model
        query.data?.data.forEach(item => {
            // PO Status data
            let poStatusRecord = result.purchaseOrderStatus[item.status];
            if (!poStatusRecord) {
                poStatusRecord = { count: 0, value: 0 };
                result.purchaseOrderStatus[item.status] = poStatusRecord;
            }
            poStatusRecord.count++;
            poStatusRecord.value += item.totalCost;

            if (item.status === PurchaseOrderStatus.Submitted) {
                // Manufacturer order core status data
                item.context.manufacturerOrderStatuses?.forEach(status => {
                    let coreStatusRecord = result.manufacturerOrderCoreStatus[status.coreStatus];
                    if (!coreStatusRecord) {
                        coreStatusRecord = { count: 0, value: 0 };
                        result.manufacturerOrderCoreStatus[status.coreStatus] = coreStatusRecord;
                    }
                    coreStatusRecord.count++;
                    coreStatusRecord.value += item.totalCost;
                });
            }
        });
        return result;
    }, [query.data?.data]);

    return (
        <DashboardOrdersWidgetsPure
            data={data}
            isLoading={query.isLoading}
            isError={query.isError}
        />
    );
}

export function DashboardOrdersWidgetsPure({
    data,
    isLoading,
    isError,
}: {
    data?: DashboardOrdersData;
    isLoading: boolean;
    isError: boolean;
}) {
    return (
        <WidgetDisplay
            isLoading={isLoading}
            isError={isError}
        >
            {data && (
                <>
                    <OrderStatusWidget
                        data={data}
                        status={PurchaseOrderStatus.Open}
                    />

                    <CoreStatusWidget
                        data={data}
                        coreStatus={ManufacturerOrderCoreStatus.Unstarted}
                    />

                    <CoreStatusWidget
                        data={data}
                        coreStatus={ManufacturerOrderCoreStatus.InProgress}
                    />

                    <CoreStatusWidget
                        data={data}
                        coreStatus={ManufacturerOrderCoreStatus.OnHold}
                    />

                    <CoreStatusWidget
                        data={data}
                        coreStatus={ManufacturerOrderCoreStatus.Completed}
                    />

                    <CoreStatusWidget
                        data={data}
                        coreStatus={ManufacturerOrderCoreStatus.Cancelled}
                    />
                </>
            )}
        </WidgetDisplay>
    );
}

function OrderStatusWidget({
    data,
    status,
}: {
    data: DashboardOrdersData;
    status: PurchaseOrderStatus;
}) {
    const record = data.purchaseOrderStatus[status] ?? {
        count: 0,
        value: 0,
    };

    return (
        <CardWidget
            title={
                <PurchaseOrderStatusBadge
                    status={status}
                    size="small"
                />
            }
            count={record.count}
            value={record.value}
            to={`/orders?status=${status}`}
        />
    );
}

function CoreStatusWidget({
    data,
    coreStatus,
}: {
    data: DashboardOrdersData;
    coreStatus: ManufacturerOrderCoreStatus;
}) {
    const record = data.manufacturerOrderCoreStatus[coreStatus] ?? {
        count: 0,
        value: 0,
    };

    return (
        <CardWidget
            title={
                <ManufacturerOrderStatusBadge
                    status={{
                        id: 0,
                        coreStatus,
                        name: ManufacturerOrderCoreStatusDisplay.display(coreStatus) as string,
                    }}
                />
            }
            count={record.count}
            value={record.value}
            to="/orders?status=SUBMITTED"
        />
    );
}
