import ErrorContent from 'components/ErrorContent/ErrorContent';
import MyLinearProgress from 'components/MyLinearProgress/MyLinearProgress';
import React, { ReactNode } from 'react';
import './WidgetDisplay.scss';

export default function WidgetDisplay({
    isLoading,
    isError,
    children,
}: {
    isLoading?: boolean;
    isError?: boolean;
    children?: ReactNode;
}) {
    return (
        <div className="WidgetDisplay">
            {isLoading ? (
                // Loading spinner
                <div className="WidgetDisplay__LoadingWrapper">
                    <MyLinearProgress />
                </div>
            ) : isError ? (
                <ErrorContent className="WidgetDisplay__Error" />
            ) : (
                <div className="WidgetDisplay__Content">{children}</div>
            )}
        </div>
    );
}
