import LayoutHeader from 'components/LayoutHeader/LayoutHeader';
import PageHeader from 'components/PageHeader/PageHeader';
import DashboardOrdersWidgets from 'features/dashboard/components/DashboardOrdersWidgets/DashboardOrdersWidgets';
import useApiTagInvalidate from 'hooks/useApiTagInvalidate';
import React from 'react';
import { Outlet } from 'react-router';
import { ApiTagType } from 'services/api';
import { usePageTitle } from 'utils/locationHelpers';
import './DashboardPage.scss';

export default function DashboardPage() {
    usePageTitle('Dashboard');

    // Refresh data when the page is loaded
    useApiTagInvalidate([ApiTagType.PurchaseOrder], { onMount: true });

    return (
        <>
            <LayoutHeader>
                <PageHeader title="Dashboard" />

                {/* Orders */}
                <PageHeader
                    className="DashboardPage__SectionHeader"
                    title="Orders"
                    titleContext="Past 30 days"
                />

                <DashboardOrdersWidgets />

                {/* Deals */}
                {/* TODO: Render DashboardDealsWidgets for each workflow */}
            </LayoutHeader>
            <Outlet />
        </>
    );
}
