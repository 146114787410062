import React from 'react';
import { Link } from 'react-router-dom';
import coalesceClassNames from 'utils/coalesceClassNames';
import { formatCurrency } from 'utils/helpers';
import './CardWidget.scss';

export default function CardWidget({
    className,
    title,
    count,
    value,
    to,
}: {
    className?: string;
    title: React.ReactNode;
    count: number;
    value: number;
    to: string;
}) {
    return (
        <Link
            to={to}
            className={coalesceClassNames('CardWidget', className)}
        >
            <span className="CardWidget__Title">{title}</span>
            <span className="CardWidget__Count">{count}</span>
            <span className="CardWidget__Value">{formatCurrency(value, { trimCents: true })}</span>
        </Link>
    );
}
